let arr = {
  'united-kingdom': [
    '447947570472',
    '447859730937',
    '447407605376',
    '447407603569',
    '447727415781',
    '447342769960',
    '447407605360',
    '447904571664',
    '447938556403',
    '447842646591'
  ],
  'united-states': [
    '19143089421',
    '19143089417',
    '13132004864',
    '14065085186',
    '14068023450',
    '14065085202',
    '14066258358',
    '14067177491',
    '12297655805',
    '12356529750',
    '124700258101',
    '14068023374'
  ],
  india: [
    '917428723247',
    '917428730894',
    '917528988312',
    '919464108205',
    '919838735597',
    '919780364281',
    '919464403482',
    '919090301842',
    '919090711863',
    '919838706902'
  ],
  china: [
    '8613396230353',
    '8615544728464',
    '8613373177179',
    '8615510282301',
    '8618149145008',
    '8615169288975',
    '8613522997139',
    '86181743250801',
    '8618174325080',
    '8613373384249',
    '8615289870773',
    '8618241022216',
    '8615453676898',
    '8613930966468',
    '8613957347911'
  ],
  australia: ['61476857122', '61485813567'],
  germany: ['4901627183786', '4901741981514', '4901741981514', '4915214834111', '4901747707507'],
  canada: ['12498028639', '12498028661', '12495926985', '18735061688', '12498028301', '12265913243', '17822015576', '12498028637', '12267853593'],
  netherlands: ['31645574200', '31616294437', '31616193885', '31616647585', '31616402922', '31616526169'],
  spain: ['34658193416', '34658192396', '34658194198', '34658192598', '34658192436'],
  philippines: ['639669917700', '639669917830', '639669917662'],
  'czech-republic': ['420703654125', '420728795236'],
  israel: ['972552992023', '972552603210'],
  croatia: ['385915079531', '385915981787', '385915093452'],
  thailand: ['66937258508', '66937197647', '66937258508'],
  mexico: ['528124136397', '528135607941', '528124135976'],
  romania: ['40731883509', '40731883257'],
  mauritius: ['23059767670', '23059768152', '23058513614'],
  portugal: ['351911820062', '351915245709'],
  morocco: ['212770540034'],
  brazil: ['559551583801'],
  nigeria: ['2348153353131'],
  belgium: ['32460222809', '32468799990'],
  austria: ['4306935893571'],
  russia: ['79963466371', '79960969443', '79587345717', '79587215117', '79316321501', '79960969031', '79289618054', '79102252641'],
  'hong-kong': [
    '85213696954576',
    '85215182839885',
    '85218247822467',
    '85215827962373',
    '85213859117712',
    '85213371313905',
    '85215111456562',
    '85215561250645',
    '852137526983921'
  ],
  ukraine: ['380971025069', '380677242829', '380682768718', '380974265121'],
  sweden: ['46726416176', '46726416175', '46726417111', '46726416173', '46726417110', '46726416174']
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index
}

Object.keys(arr).forEach((key) => {
  arr[key] = arr[key].filter(onlyUnique)
})

module.exports = arr
