import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer } from './ui/Div'
import Button from './ui/Button'
import Spinner from './ui/Spinner'
import { shuffle } from './ui/helpers.js'
import { urlToString } from './ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import telephones from '../../constants/telephones'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from './ui/Text'
import OnlineIcon from './ui/OnlineIcon'
import Image from './ui/Image'

const FlagImage = styled.img`
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="60px" height="60px" />
}
const CountryContainer = styled(Flex)`
  background-color: white;
  border-radius: 24px;

  cursor: pointer;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  &&:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  }
`
const Grid = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const CountryNumbers = ({ iso2 }) => {
  return (
    <Grid mb="60px" height="fit-content">
      {Object.keys(telephones).map((otherCountry) => {
        return (
          <a key={otherCountry} href={`/${otherCountry}/`}>
            <CountryContainer>
              <Flex>
                <Flag iso2={countryToIso2[otherCountry]} />
                <Flex flexDirection="column" justifyContent="center">
                  <Text mb="8px" fontWeight="500" fontSize="20px" px="16px" mbtextAlign="center" A>
                    {urlToString(otherCountry)}
                  </Text>
                  <Text fontSize="16px" px="16px" mbtextAlign="center" A>
                    Active numbers: {telephones[otherCountry].length}
                  </Text>
                </Flex>
                <OnlineIcon />
              </Flex>
            </CountryContainer>
          </a>
        )
      })}
    </Grid>
  )
}
export default CountryNumbers
