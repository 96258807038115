import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer } from './ui/Div'
import Button from './ui/Button'
import Spinner from './ui/Spinner'
import { shuffle } from './ui/helpers.js'
import { urlToString } from './ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import telephones from '../../constants/telephones'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from './ui/Text'
import OnlineIcon from './ui/OnlineIcon'
import Image from './ui/Image'

const FlagImage = styled.img`
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="60px" height="60px" />
}

const TelephoneContainer = styled(Flex)`
  position: relative;
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 500px) {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
`

const NumberGrid = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 450px) {
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
`

const PopularNumbers = ({ iso2 }) => {
  let popularTelephones = []
  Object.keys(telephones).forEach((country) => {
    popularTelephones = popularTelephones.concat(
      telephones[country].map((tel) => {
        return {
          telephone: tel,
          country
        }
      })
    )
  })
  popularTelephones = popularTelephones.slice(0, 20)
  return (
    <Flex flexDirection="column" mb="48px">
      <H3 mb="16px">Featured Numbers</H3>
      <NumberGrid>
        {shuffle(popularTelephones).map(({ telephone, country }) => {
          return (
            <a key={telephone} href={`/${country}/${telephone}/`}>
              <TelephoneContainer>
                <Flex alignItems="center">
                  <Flag iso2={countryToIso2[country]} />
                  <Flex flexDirection="column" ml="16px">
                    <Text width="100%" fontSize="26px" textAlign="left" mr={2} mb="8px">
                      +{telephone}
                    </Text>
                  </Flex>
                  <OnlineIcon />
                </Flex>
              </TelephoneContainer>
            </a>
          )
        })}
      </NumberGrid>
    </Flex>
  )
}
export default PopularNumbers
