import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer } from './ui/Div'
import Button from './ui/Button'
import Spinner from './ui/Spinner'
import { colors, smallBoxShadow } from './ui/helpers.js'
import { urlToString } from './ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'

import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from './ui/Text'
import OnlineIcon from './ui/OnlineIcon'
import Image from './ui/Image'

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const Container = styled(Flex)`
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
`

const FlagImage = styled.img`
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  margin-left: 16px;
`

const Header = ({ iso2 }) => {
  return (
    <Container bg="white" justifyContent="center" px="40px" py="24px" mb="24px">
      <Flex maxWidth="1200px" justifyContent="space-between">
        <a href="/">
          <Text mt="4px">Home</Text>
        </a>
        <Flex width="auto">
          <a href="/united-states/">
            <Flag iso2={'US'} />
          </a>
          <a href="/india/">
            <Flag iso2={'IN'} />
          </a>
          <a href="/united-kingdom/">
            <Flag iso2={'GB'} />
          </a>
          <a href="/china/">
            <Flag iso2={'CN'} />
          </a>
          <DesktopContainer>
            <a href="/canada/">
              <Flag iso2={'CA'} />
            </a>
            <a href="/australia/">
              <Flag iso2={'AU'} />
            </a>
            <a href="/germany/">
              <Flag iso2={'DE'} />
            </a>
            <a href="/">
              <Flex alignItems="center" width="auto" height="30px">
                <Text ml="16px">All Countries</Text>
              </Flex>
            </a>
          </DesktopContainer>
        </Flex>
      </Flex>
    </Container>
  )
}
export default Header
